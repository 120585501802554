import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Divider,
  Avatar,
  CircularProgress,
  Box,
  Tabs,
  Tab,
  Grid,
  Container,
  IconButton,
} from "@mui/material";
import {
  ArrowBottom,
  chatBotIcon,
  dateIcon,
  departmentIcon,
  paperclip,
  photoIcon,
  plusIcon,
  searchIcon,
  timeIcon,
} from "../../assets";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { fetchOperators } from "../../slices/operaters";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import {
  assignConversationToAnotherOperator,
  clientConversationStored as clientConversationStoredFunction,
  getAllConversationsByDate,
  getAllConversationsByDepartment,
  getAllConversationsByMeta,
  getAllConversationsByMinutes,
  getAllConversationsByOperator,
  getConvByClient,
  getConversationQuery,
  getOneConversationByClient,
  resetConversationsStored,
  resetPage,
} from "../../slices/conversation";

import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { DateRange } from "react-date-range";
import { useHistory, useLocation } from "react-router-dom";
import {
  clientsSearch,
  conversationsArchivedSearch,
  conversationsSearch,
  generalArchivedSearch,
  generalSearch,
  getMetaKeys,
  isHadAConversation,
  messagesArchivedSearch,
  messagesSearch,
} from "../../slices/search";
import PropTypes from "prop-types";
import { newColorFind } from "../../utilities/randomColor";
import {
  conversationMessages,
  resetMessagesStored,
  storeMessages,
} from "../../slices/messages";
import { emitEvent } from "../../socket/socket";
import { isFound } from "../../utilities/isFound";
import constants from "../../utilities/constants";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { fr, ar, enUS } from "date-fns/locale";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { nanoid } from "nanoid";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { savePersonalFilter } from "../../slices/filters";
import { emptyKeyOrValue } from "../../utilities/emptyKeyOrValue";
import {
  getArchivedMessages,
  storeArchivedMessages,
} from "../../slices/archivedMessages";
import {
  getOneArchivedConversation,
  storeArchivedConversation,
} from "../../slices/archivedConversation";
import { openModal } from "../../slices/modals";
import { ConvertingArabicNumberToEnglish } from "../../utilities/toEnglishNumber";
import moment from "moment";
import { isIframe, parseHtml } from "../../utilities/ParseHtmlToText";
import { isImage } from "../../utilities/isImage";
import {
  getConversationsBySpecificRating,
  resetConversationsBySpecificRating,
} from "../../slices/statistics";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AssignOperatorModal = React.memo(({ id, open, handleClose, data }) => {
  const [value, setValue] = React.useState(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const dispatch = useDispatch();
  const { operators, departments, statusGetDepartments, statusOperatorsList } =
    useSelector((state) => state.operators);
  const {
    searchStatus,
    messagesSearchStatus,
    messagesArchivedSearchStatus,
    conversationsSearchStatus,
    conversationsArchivedSearchStatus,
    searchResults,
    searchArchivedResults,
    totalPagesForMessages,
    totalPagesForArchivedMessages,
    totalPagesForConversations,
    totalPagesForArchivedConversations,
    totalPagesForClients,
    clientsSearchStatus,
    searchArchivedStatus,
    isHadAConversationStatus,
    metaKeys,
    statusGetMetaKeys,
  } = useSelector((state) => state.search);
  const {
    statusConversationsByRating,
    conversationsBySpecificRating,
    hasNextPageForConversationsBySpecificRating,
  } = useSelector((state) => state.statistics);
  const {
    conversationsStored,
    clientConversationStored,
    status,
    statusAssignClientToAnotherConversation,
    query,
  } = useSelector((state) => state.conversations);
  const { archivedConversations } = useSelector(
    (state) => state.archivedConversation
  );
  const [pageForMessages, setPageForMessages] = useState(2);
  const [pageForConversations, setPageForConversations] = useState(2);
  const [pageForRatingConversations, setPageForRatingConversations] =
    useState(2);
  const [pageForClients, setPageForClients] = useState(2);
  const [pageForArchivedMessages, setPageForArchivedMessages] = useState(2);
  const [pageForArchivedConversations, setPageForArchivedConversations] =
    useState(2);
  const { messages, messagesStored } = useSelector((state) => state.messages);
  const { archivedMessages, archivedMessagesStored } = useSelector(
    (state) => state.archivedMessages
  );
  const filterByMetaStatus = useSelector((state) => state.filters.status);
  const [name, setName] = useState("");
  const [operatorId, setOperatorId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [display, setDisplay] = useState(false);
  const [searchDisplay, setSearchDisplay] = useState(false);
  const [searchArchivedDisplay, setSearchArchivedDisplay] = useState(false);
  const [valueTab, setValueTab] = React.useState(0);
  const [meta, setMeta] = useState({ optional: [] });
  const { t } = useTranslation();
  const handleChange = (_, newValue) => {
    setValueTab(newValue);
  };
  function formatDate(date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    return year + "-" + month + "-" + day;
  }
  const [search, setSearch] = useState("");
  const [searchDepartment, setSearchDepartment] = useState("");
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  useEffect(() => {
    operators?.length === 0 &&
      (data?.id === "assign-operator-modal" ||
        data?.id === "filter-conversation-by-operator-modal") &&
      dispatch(fetchOperators({ websiteID: data?.websiteId, query: "" }));
  }, [
    data?.id === "assign-operator-modal" ||
      data?.id === "filter-conversation-by-operator-modal",
  ]);

  const wrapperRef = useRef(null);
  const wrapperSearchRef = useRef(null);
  const SearchResults = {
    ...(data?.id === "archived-search-modal"
      ? searchArchivedResults
      : searchResults),
    messages: (data?.id === "archived-search-modal"
      ? searchArchivedResults
      : searchResults
    )?.messages?.map((message) => {
      let name;
      let avatar;
      message?.from === "CLIENT" &&
        (data?.id === "archived-search-modal"
          ? archivedConversations
          : conversationsStored
        )?.forEach((element) => {
          element?._id === message?.conversation &&
            Object.keys(element?.meta).length > 1 &&
            (() => {
              avatar = element?.meta?.avatar;
              name = element?.meta?.name;
            })();
        });
      return message?.from === "CLIENT" &&
        (name !== undefined || avatar !== undefined)
        ? { ...message, name, avatar }
        : message;
    }),
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutsideSearch);

    return () => {
      window.removeEventListener("mousedown", handleClickOutsideSearch);
    };
  });
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
      if (
        event.srcElement ===
        document.getElementById("assign-operator-cancel-btn")
      ) {
        handleClose(id);
      }
    }
  };

  const handleClickOutsideSearch = (event) => {
    const { current: wrap } = wrapperSearchRef;
    if (wrap && !wrap.contains(event.target)) {
      data?.id === "archived-search-modal"
        ? setSearchArchivedDisplay(false)
        : setSearchDisplay(false);
    }
  };

  const handleChangeSearch = useCallback(
    (websiteID, key, id) => {
      valueTab === 0 &&
        (id === "archived-search-modal"
          ? setPageForArchivedMessages(2)
          : setPageForMessages(2));
      valueTab === 1 &&
        (id === "archived-search-modal"
          ? setPageForArchivedConversations(2)
          : setPageForConversations(2));
      key !== ""
        ? id === "archived-search-modal"
          ? setSearchArchivedDisplay({ websiteID, key })
          : setSearchDisplay({ websiteID, key })
        : id === "archived-search-modal"
        ? setSearchArchivedDisplay(false)
        : setSearchDisplay(false);
    },
    [dispatch]
  );
  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      searchDisplay !== false && dispatch(generalSearch(searchDisplay));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchDisplay]);
  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      searchArchivedDisplay !== false &&
        dispatch(generalArchivedSearch(searchArchivedDisplay));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchArchivedDisplay]);
  const Option = (props) => {
    return (
      <components.Option {...props} className="select-option">
        <div className="radio-btn-label-wrapper">
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            className="select-option-radio"
          />

          <div className="select-option-label">{props.label}</div>
        </div>
      </components.Option>
    );
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <LazyLoadImage src={ArrowBottom} />
      </components.DropdownIndicator>
    );
  };
  const breakLiner = (string) => {
    return (
      <div
        // className={` ${"message-content"}`}
        dangerouslySetInnerHTML={{
          __html: string,
        }}
      />
    );
  };
  const styles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
    }),
    control: (base, state) => ({
      ...base,
      border: "1px solid #ebebeb",
      width: "260px",
      borderRadius: "8px",
      height: "50px",
      backgroundColor: "white",
      borderbottom: state.isFocused ? "unset" : "1px solid #ebebeb",
      boxShadow: state.isFocused ? "0px " : "0px",
      "&:hover": {
        ...styles,
      },
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: "100%",
      boxShadow: "0px",
      borderRadius: "8px",
      border: "1px solid #ebebeb",
    }),
    option: (provided) => ({
      ...provided,
      fontWeight: "normal",
      color: "#78787A",
      width: "100%",
      backgroundColor: "white",
      fontSize: "12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#78787A",
      width: "100%",
      fontSize: "12px",
    }),
  };
  const [selectedValue, setSelectedValue] = useState({
    label: t("Filter By Time"),
    value: "",
  });
  const handleSelectChange = (e) => {
    setSelectedValue(e);
  };

  const { i18n } = useTranslation();

  return "conversations-by-rating-modal" === data?.id ? (
    <Dialog
      open={open}
      onClose={() => {
        setPageForRatingConversations(2);
        dispatch(resetConversationsBySpecificRating());
        handleClose(id);
      }}
      className={"search-modal"}
      PaperProps={{
        sx: { position: "fixed", top: 0, width: "100%" },
      }}
      ref={wrapperSearchRef}
    >
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={valueTab}
              onChange={handleChange}
              textColor="inherit"
              aria-label="basic tabs example"
              TabIndicatorProps={{
                sx: { backgroundColor: "#6932FA" },
              }}
            >
              <Tab
                label={
                  <div
                    className={
                      statusConversationsByRating === "loading"
                        ? "circular-progress-rating-conversations-wrapper"
                        : ""
                    }
                  >
                    {statusConversationsByRating === "loading" && (
                      <CircularProgress className="circular-progress-rating-conversations" />
                    )}
                    <span>{t("Conversations")}</span>
                  </div>
                }
                {...a11yProps(0)}
                className="tab-label"
              />
            </Tabs>
          </Box>
          <TabPanel value={valueTab} index={0}>
            <Container>
              {statusConversationsByRating ===
              "loading" ? null : conversationsBySpecificRating?.length === 0 ? (
                <div className="no-messages-text">
                  <span> {t("No conversations found with that emoji")}</span>
                </div>
              ) : (
                <React.Fragment>
                  {conversationsBySpecificRating?.map((el, index) => (
                    <div
                      className="filtered-contacts"
                      key={el?._id}
                      onClick={() => {
                        (async () => {
                          if (!isFound(messages, el?._id)) {
                            await dispatch(
                              getOneConversationByClient({
                                conversationId: el?._id,
                                user: data?.user,
                                websiteId: data?.websiteID,
                                userId: data?.user?._id,
                              })
                            );
                            dispatch(
                              conversationMessages({
                                conversationId: el?._id,
                                websiteId: data?.websiteID,
                                page: 1,
                              })
                            );
                          } else {
                            messagesStored[0]?.conversation !== el?._id &&
                              (() => {
                                dispatch(storeMessages(el?._id));
                                dispatch(
                                  clientConversationStoredFunction(el?._id)
                                );
                              })();
                          }
                        })();

                        handleClose(id);
                        pathname !== "/messaging" && history.push("/messaging");
                      }}
                    >
                      <Grid
                        container
                        style={{ padding: "10px", margin: "10px auto" }}
                      >
                        <Grid item xs={2} sm={2} md={2}>
                          <Avatar
                            //variant="rounded"
                            sx={{
                              width: 56,
                              height: 56,
                              bgcolor: newColorFind(index),
                            }}
                            src={el?.meta?.avatar}
                          >
                            {el?.meta?.name?.split("")[0]}
                          </Avatar>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sm={10}
                          md={10}
                          style={{ marginTop: "15px" }}
                        >
                          <h5>{el?.meta?.name || "Guest"}</h5>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                  {conversationsBySpecificRating?.length >= 10 &&
                    hasNextPageForConversationsBySpecificRating && (
                      <span
                        className={
                          statusConversationsByRating === "loading"
                            ? "see-more see-more-disabled"
                            : "see-more"
                        }
                        onClick={() => {
                          dispatch(
                            getConversationsBySpecificRating({
                              websiteID: data?.websiteID,
                              rating: data?.rating,
                              page: pageForRatingConversations,
                              period: data?.period,
                            })
                          )?.then((res) => {
                            !res?.error &&
                              setPageForRatingConversations((prev) => prev + 1);
                          });
                        }}
                      >
                        {t("See more...")}
                      </span>
                    )}
                </React.Fragment>
              )}
            </Container>
          </TabPanel>
        </Box>
      </div>
    </Dialog>
  ) : ["search-modal", "archived-search-modal"].includes(data?.id) ? (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      className={
        !["search-modal", "archived-search-modal"].includes(data?.id)
          ? "info-modal"
          : "search-modal"
      }
      PaperProps={{
        sx: { position: "fixed", top: 0, width: "100%" },
      }}
      ref={wrapperSearchRef}
    >
      <div className="search-input-modal">
        {(data?.id === "archived-search-modal"
          ? searchArchivedStatus
          : searchStatus) === "loading" ? (
          <CircularProgress className="circular-progress-general-search" />
        ) : (
          <LazyLoadImage src={searchIcon} alt="search-icon" />
        )}

        <input
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
          autoFocus
          type="text"
          placeholder={
            data?.id === "archived-search-modal"
              ? t("Search For Archived Conversations,messages,names...")
              : t("Search Conversations,messages,names...")
          }
          onChange={(e) =>
            handleChangeSearch(data?.websiteID, e.target.value, data?.id)
          }
        />
      </div>
      {(data?.id === "archived-search-modal"
        ? searchArchivedDisplay
        : searchDisplay) &&
        (data?.id === "archived-search-modal"
          ? searchArchivedStatus
          : searchStatus) === "success" && (
          <div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueTab}
                  onChange={handleChange}
                  textColor="inherit"
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    sx: { backgroundColor: "#6932FA" },
                  }}
                >
                  <Tab
                    label={t("Messages")}
                    {...a11yProps(0)}
                    className="tab-label"
                  />
                  <Tab
                    label={t("Conversations")}
                    {...a11yProps(0)}
                    className="tab-label"
                  />
                  {data?.id === "search-modal" && (
                    <Tab
                      label={t("Contacts")}
                      {...a11yProps(0)}
                      className="tab-label"
                    />
                  )}
                </Tabs>
              </Box>
              <TabPanel value={valueTab} index={0}>
                <Container>
                  {SearchResults?.messages?.length === 0 ? (
                    <div className="no-messages-text">
                      <span> No messages found with that query</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {SearchResults?.messages?.map((message, index) => (
                        <div
                          className="filtered-messages-card"
                          key={message?._id}
                          onClick={() => {
                            data?.id === "archived-search-modal"
                              ? (async () => {
                                  if (
                                    !isFound(
                                      archivedMessages,
                                      message?.conversation
                                    )
                                  ) {
                                    await dispatch(
                                      getOneArchivedConversation({
                                        websiteID: data?.websiteID,
                                        conversationID: message?.conversation,
                                      })
                                    );
                                    dispatch(
                                      getArchivedMessages({
                                        websiteID: data?.websiteID,
                                        conversationID: message?.conversation,
                                        page: 1,
                                      })
                                    );
                                  } else {
                                    archivedMessagesStored[0]?.conversation !==
                                      message?.conversation &&
                                      (() => {
                                        dispatch(
                                          storeArchivedMessages(
                                            message?.conversation
                                          )
                                        );
                                        dispatch(
                                          storeArchivedConversation(
                                            message?.conversation
                                          )
                                        );
                                      })();
                                  }
                                })()
                              : (async () => {
                                  if (
                                    !isFound(messages, message?.conversation)
                                  ) {
                                    await dispatch(
                                      getOneConversationByClient({
                                        conversationId: message?.conversation,
                                        user: data?.user,
                                        websiteId: data?.websiteID,
                                        userId: data?.user?._id,
                                      })
                                    );
                                    dispatch(
                                      conversationMessages({
                                        conversationId: message?.conversation,
                                        websiteId: data?.websiteID,
                                        page: 1,
                                      })
                                    );
                                  } else {
                                    messagesStored[0]?.conversation !==
                                      message?.conversation &&
                                      (() => {
                                        dispatch(
                                          storeMessages(message?.conversation)
                                        );
                                        dispatch(
                                          clientConversationStoredFunction(
                                            message?.conversation
                                          )
                                        );
                                      })();
                                  }
                                })();

                            handleClose(id);
                            data?.id === "archived-search-modal"
                              ? pathname !== "/archived" &&
                                history.push("/archived")
                              : pathname !== "/messaging" &&
                                history.push("/messaging");
                          }}
                        >
                          <Grid
                            container
                            style={{ padding: "10px", margin: "10px auto" }}
                          >
                            <Grid item xs={2} sm={2} md={2}>
                              <Avatar
                                sx={{
                                  width: 56,
                                  height: 56,
                                  bgcolor: newColorFind(index),
                                }}
                                src={
                                  message?.from === "CLIENT"
                                    ? message?.avatar
                                    : message?.from === "OPERATOR"
                                    ? message?.createdBy?.avatar
                                    : message?.from === "BOT"
                                    ? chatBotIcon
                                    : ""
                                }
                              >
                                {message?.name?.split("")[0]}
                              </Avatar>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              sm={10}
                              md={10}
                              style={{ marginTop: !message?.name && "10px" }}
                            >
                              <h5>
                                {message?.from === "OPERATOR"
                                  ? message?.createdBy != null &&
                                    `${message?.createdBy?.firstName} ${message?.createdBy?.lastName}`
                                  : message?.from === "BOT"
                                  ? "BOT"
                                  : message != null && message?.name}
                              </h5>
                              <span
                                className="conversation-search"
                                dangerouslySetInnerHTML={{
                                  __html: message?.content,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      {SearchResults?.messages?.length >= 10 &&
                        ((data?.id === "archived-search-modal"
                          ? pageForArchivedMessages
                          : pageForMessages) <=
                          (data?.id === "archived-search-modal"
                            ? totalPagesForArchivedMessages
                            : totalPagesForMessages) ||
                          (data?.id === "archived-search-modal"
                            ? totalPagesForArchivedMessages
                            : totalPagesForMessages) === null) && (
                          <span
                            className={
                              (data?.id === "archived-search-modal"
                                ? messagesArchivedSearchStatus
                                : messagesSearchStatus) === "loading"
                                ? "see-more see-more-disabled"
                                : "see-more"
                            }
                            onClick={() => {
                              data?.id === "archived-search-modal"
                                ? dispatch(
                                    messagesArchivedSearch({
                                      websiteID: data?.websiteID,
                                      key: searchArchivedDisplay?.key,
                                      page: pageForArchivedMessages,
                                    })
                                  )?.then((res) => {
                                    !res?.error &&
                                      setPageForArchivedMessages(
                                        (prev) => prev + 1
                                      );
                                  })
                                : dispatch(
                                    messagesSearch({
                                      websiteID: data?.websiteID,
                                      key: searchDisplay?.key,
                                      page: pageForMessages,
                                    })
                                  )?.then((res) => {
                                    !res?.error &&
                                      setPageForMessages((prev) => prev + 1);
                                  });
                            }}
                          >
                            {t("See more...")}
                          </span>
                        )}
                    </React.Fragment>
                  )}
                </Container>
              </TabPanel>
              <TabPanel value={valueTab} index={1}>
                <Container>
                  {SearchResults?.conversations?.length === 0 ? (
                    <div className="no-messages-text">
                      <span>
                        {" "}
                        {t("No conversations found with that query")}
                      </span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {SearchResults?.conversations?.map((el, index) => (
                        <div
                          className="filtered-contacts"
                          key={el?._id}
                          onClick={() => {
                            data?.id === "archived-search-modal"
                              ? (async () => {
                                  if (!isFound(archivedMessages, el?._id)) {
                                    (async () => {
                                      await dispatch(
                                        getOneArchivedConversation({
                                          websiteID: data?.websiteID,
                                          conversationID: el?._id,
                                        })
                                      );
                                      dispatch(
                                        getArchivedMessages({
                                          websiteID: data?.websiteID,
                                          conversationID: el?._id,
                                          page: 1,
                                        })
                                      );
                                    })();
                                  } else {
                                    archivedMessagesStored[0]?.conversation !==
                                      el?._id &&
                                      (() => {
                                        dispatch(
                                          storeArchivedMessages(el?._id)
                                        );
                                        dispatch(
                                          storeArchivedConversation(el?._id)
                                        );
                                      })();
                                  }
                                })()
                              : (async () => {
                                  if (!isFound(messages, el?._id)) {
                                    await dispatch(
                                      getOneConversationByClient({
                                        conversationId: el?._id,
                                        user: data?.user,
                                        websiteId: data?.websiteID,
                                        userId: data?.user?._id,
                                      })
                                    );
                                    dispatch(
                                      conversationMessages({
                                        conversationId: el?._id,
                                        websiteId: data?.websiteID,
                                        page: 1,
                                      })
                                    );
                                  } else {
                                    messagesStored[0]?.conversation !==
                                      el?._id &&
                                      (() => {
                                        dispatch(storeMessages(el?._id));
                                        dispatch(
                                          clientConversationStoredFunction(
                                            el?._id
                                          )
                                        );
                                      })();
                                  }
                                })();

                            handleClose(id);
                            data?.id === "archived-search-modal"
                              ? pathname !== "/archived" &&
                                history.push("/archived")
                              : pathname !== "/messaging" &&
                                history.push("/messaging");
                          }}
                        >
                          <Grid
                            container
                            style={{ padding: "10px", margin: "10px auto" }}
                          >
                            <Grid item xs={2} sm={2} md={2}>
                              <Avatar
                                //variant="rounded"
                                sx={{
                                  width: 56,
                                  height: 56,
                                  bgcolor: newColorFind(index),
                                }}
                                src={el?.meta?.avatar}
                              >
                                {el?.meta?.name?.split("")[0]}
                              </Avatar>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              sm={10}
                              md={10}
                              style={{ marginTop: "15px" }}
                            >
                              <h5>{el?.meta?.name || "Guest"}</h5>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      {SearchResults?.conversations?.length >= 10 &&
                        ((data?.id === "archived-search-modal"
                          ? pageForArchivedConversations
                          : pageForConversations) <=
                          (data?.id === "archived-search-modal"
                            ? totalPagesForArchivedConversations
                            : totalPagesForConversations) ||
                          (data?.id === "archived-search-modal"
                            ? totalPagesForArchivedConversations
                            : totalPagesForConversations) === null) && (
                          <span
                            className={
                              (data?.id === "archived-search-modal"
                                ? conversationsArchivedSearchStatus
                                : conversationsSearchStatus) === "loading"
                                ? "see-more see-more-disabled"
                                : "see-more"
                            }
                            onClick={() => {
                              data?.id === "archived-search-modal"
                                ? dispatch(
                                    conversationsArchivedSearch({
                                      websiteID: data?.websiteID,
                                      key: searchArchivedDisplay?.key,
                                      page: pageForArchivedConversations,
                                    })
                                  )?.then((res) => {
                                    !res?.error &&
                                      setPageForArchivedConversations(
                                        (prev) => prev + 1
                                      );
                                  })
                                : dispatch(
                                    conversationsSearch({
                                      websiteID: data?.websiteID,
                                      key: searchDisplay?.key,
                                      page: pageForConversations,
                                    })
                                  )?.then((res) => {
                                    !res?.error &&
                                      setPageForConversations(
                                        (prev) => prev + 1
                                      );
                                  });
                            }}
                          >
                            {t("See more...")}
                          </span>
                        )}
                    </React.Fragment>
                  )}
                </Container>
              </TabPanel>
              <TabPanel value={valueTab} index={2}>
                <Container>
                  {SearchResults?.users?.length === 0 ? (
                    <div className="no-messages-text">
                      <span> No contacts found with that query</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {SearchResults?.users?.map((el, index) => (
                        <div
                          className="filtered-contacts"
                          style={{
                            pointerEvents:
                              isHadAConversationStatus === el?.id && "none",
                          }}
                          key={el?._id}
                          onClick={() => {
                            dispatch(
                              isHadAConversation({
                                websiteID: data?.websiteID,
                                userID: el?.id,
                              })
                            ).then((res) => {
                              handleClose(id);
                              res?.payload?._id
                                ? (() => {
                                    const id = res?.payload?._id;
                                    const user = data?.user;
                                    history.push(
                                      `/messaging/${user?.websiteID}/${id}`
                                    );
                                    if (!isFound(messages, id)) {
                                      (async () => {
                                        await dispatch(
                                          getOneConversationByClient({
                                            conversationId: id,
                                            user,
                                            websiteId: data?.websiteID,
                                            userId: user?._id,
                                          })
                                        ).then((res) => {
                                          const conversation =
                                            conversationsStored?.find(
                                              (item) => item?._id === id
                                            );
                                          !res?.error &&
                                            user?.role?.code === "OPERATOR" &&
                                            typeof res?.payload?.data?.assigned
                                              ?.user === "string" &&
                                            emitEvent("ASSIGNED_OPERATOR", {
                                              webSiteId: data?.websiteID,
                                              conversation: id,
                                              newAssignedOperator: user,
                                            });
                                          !res?.error &&
                                            (typeof res?.payload?.data?.assigned
                                              ?.user === "string" ||
                                              res?.payload?.data?.assigned?.user
                                                ?._id === user?._id) &&
                                            conversation?.unread !== 0 &&
                                            emitEvent("SET_UNREAD_MESSAGES", {
                                              conversation: conversation?._id,
                                              websiteID: data?.websiteID,
                                            });
                                        });
                                        dispatch(
                                          conversationMessages({
                                            conversationId: id,
                                            websiteId: data?.websiteID,
                                            page: 1,
                                          })
                                        );
                                      })();
                                    } else {
                                      messagesStored[0]?.conversation !== id &&
                                        (() => {
                                          dispatch(storeMessages(id));
                                          dispatch(
                                            clientConversationStoredFunction(id)
                                          );
                                          dispatch(
                                            getConvByClient({
                                              conversationId: id,
                                              user,
                                              websiteId: data?.websiteID,
                                              userId: user?._id,
                                            })
                                          ).then((res) => {
                                            const conversation =
                                              conversationsStored?.find(
                                                (item) => item?._id === id
                                              );
                                            !res?.error &&
                                              (typeof res?.assigned?.user ===
                                                "string" ||
                                                res?.assigned?.user?._id ===
                                                  user?._id) &&
                                              conversation?.unread !== 0 &&
                                              emitEvent("SET_UNREAD_MESSAGES", {
                                                conversation: conversation?._id,
                                                websiteID: data?.websiteID,
                                              });
                                          });
                                        })();
                                    }
                                  })()
                                : (async () => {
                                    Object.keys(clientConversationStored)
                                      .length !== 0 &&
                                      (await dispatch(
                                        resetConversationsStored()
                                      ));
                                    Object.keys(messagesStored).length !== 0 &&
                                      (await dispatch(resetMessagesStored()));
                                    dispatch(
                                      openModal("update-operator", {
                                        id: "drop-message",
                                        client: el,
                                      })
                                    );
                                  })();
                            });
                          }}
                        >
                          <Grid
                            container
                            style={{ padding: "10px", margin: "10px auto" }}
                          >
                            <Grid item xs={2} sm={2} md={2}>
                              {isHadAConversationStatus === el?.id ? (
                                <CircularProgress
                                  style={{
                                    width: "56px",
                                    height: "56px",
                                    color: "#6932F9",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    width: 56,
                                    height: 56,
                                    bgcolor: newColorFind(index),
                                  }}
                                  src={el?.avatar}
                                >
                                  {`${el?.name
                                    ?.split("")[0]
                                    ?.toUpperCase()} ${el?.name
                                    ?.split("")[1]
                                    ?.toUpperCase()} `}
                                </Avatar>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              sm={10}
                              md={10}
                              style={{ marginTop: "15px" }}
                            >
                              <h5>{el?.name || "Guest"}</h5>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      {SearchResults?.users?.length >= 10 &&
                        (pageForClients <= totalPagesForClients ||
                          totalPagesForClients === null) && (
                          <span
                            className={
                              clientsSearchStatus === "loading"
                                ? "see-more see-more-disabled"
                                : "see-more"
                            }
                            onClick={() => {
                              dispatch(
                                clientsSearch({
                                  websiteID: data?.websiteID,
                                  key: searchDisplay?.key,
                                  page: pageForClients,
                                })
                              )?.then((res) => {
                                !res?.error &&
                                  setPageForClients((prev) => prev + 1);
                              });
                            }}
                          >
                            {t("See more...")}
                          </span>
                        )}
                    </React.Fragment>
                  )}
                </Container>
              </TabPanel>
            </Box>
          </div>
        )}
    </Dialog>
  ) : (
    <Dialog open={open} onClose={() => handleClose(id)} className="info-modal">
      {data?.id === "filter-conversations-by-meta-modal" &&
        ["SUPER-ADMIN", "ADMIN"].includes(data?.role) && (
          <div className="close-modal-icon" onClick={() => handleClose(id)}>
            <CloseRoundedIcon />
          </div>
        )}
      <LazyLoadImage
        src={data?.icon}
        className={`assign-icon ${
          data?.id === "filter-conversations-by-meta-modal"
            ? "assign-meta-modal-icon"
            : ""
        }`}
        alt="assign-icon"
      />
      <DialogTitle className="assign-operator-title">{data?.title}</DialogTitle>
      <DialogContent
        style={{
          overflow: "hidden",
          height: data?.id === "filter-conversations-by-time-modal" && "300px",
        }}
      >
        <DialogContentText className="assign-operator-text">
          {data?.description}
        </DialogContentText>
        {data?.id === "edit-message-history" &&
          (data?.messages?.length === 0 || !data?.messages ? (
            <div className="no-edit-message">
              {t("This message is yet to be edited")}
            </div>
          ) : (
            <div className="edit-message-history-container">
              {data?.messages?.map((message) => (
                <div key={message?._id} className="edited-message">
                  <div
                    dir="auto"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      position: "relative",
                      padding: message?.isNote ? "5px" : 0,
                    }}
                  >
                    {message?.content && message?.file ? (
                      <React.Fragment>
                        {parseHtml(message.content).includes("+?+?+")
                          ? parseHtml(message.content)
                              .split("+?+?+")
                              .map((item, idx) => {
                                if (isImage(item)) {
                                  return (
                                    <div
                                      className="file-number-wrapper"
                                      style={{ alignItems: "center" }}
                                    >
                                      <div className="file-holder">
                                        <LazyLoadImage
                                          src={
                                            message?.filetype?.includes("image")
                                              ? photoIcon
                                              : paperclip
                                          }
                                          alt={
                                            message?.filetype?.includes("image")
                                              ? "photo-icon"
                                              : "paper clip"
                                          }
                                        />
                                      </div>
                                       
                                      {message?.file && (
                                        <a
                                          href={message?.file}
                                          target="_blank"
                                          className="file-name-comment"
                                          style={{ color: "black" }}
                                        >
                                          File
                                        </a>
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={idx}>{breakLiner(item)}</div>
                                  );
                                }
                              })
                          : isIframe(message.content).includes("#?#?#")
                          ? isIframe(message.content)
                              .split("#?#?#")
                              .map((item, idx) => {
                                if (item.includes("https")) {
                                  return (
                                    <div
                                      className="file-number-wrapper"
                                      style={{ alignItems: "center" }}
                                    >
                                      <div className="file-holder">
                                        <LazyLoadImage
                                          src={
                                            message?.filetype?.includes("image")
                                              ? photoIcon
                                              : paperclip
                                          }
                                          alt={
                                            message?.filetype?.includes("image")
                                              ? "photo-icon"
                                              : "paper clip"
                                          }
                                        />
                                      </div>
                                       
                                      {message?.file && (
                                        <a
                                          href={message?.file}
                                          target="_blank"
                                          className="file-name-comment"
                                          style={{ color: "black" }}
                                        >
                                          File
                                        </a>
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={idx}>{breakLiner(item)}</div>
                                  );
                                }
                              })
                          : breakLiner(message.content)}
                        <div
                          className="file-number-wrapper"
                          style={{ alignItems: "center" }}
                        >
                          <div className="file-holder">
                            <LazyLoadImage
                              src={
                                message?.filetype?.includes("image")
                                  ? photoIcon
                                  : paperclip
                              }
                              alt={
                                message?.filetype?.includes("image")
                                  ? "photo-icon"
                                  : "paper clip"
                              }
                            />
                          </div>
                           
                          {message?.file && (
                            <a
                              href={message?.file}
                              target="_blank"
                              className="file-name-comment"
                              style={{ color: "black" }}
                            >
                              File
                            </a>
                          )}
                        </div>
                      </React.Fragment>
                    ) : message?.file && !message?.content ? (
                      <React.Fragment>
                        <div
                          className="file-number-wrapper"
                          style={{ alignItems: "center" }}
                        >
                          <div className="file-holder">
                            <LazyLoadImage
                              src={
                                message?.filetype?.includes("image")
                                  ? photoIcon
                                  : paperclip
                              }
                              alt={
                                message?.filetype?.includes("image")
                                  ? "photo-icon"
                                  : "paper clip"
                              }
                            />
                          </div>
                           
                          {message?.file && (
                            <a
                              href={message?.file}
                              target="_blank"
                              className="file-name-comment"
                              style={{ color: "black" }}
                            >
                              File
                            </a>
                          )}
                        </div>
                      </React.Fragment>
                    ) : parseHtml(message.content).includes("+?+?+") ? (
                      parseHtml(message.content)
                        .split("+?+?+")
                        .map((item, idx) => {
                          if (isImage(item)) {
                            return (
                              <div
                                className="file-number-wrapper"
                                style={{ alignItems: "center" }}
                              >
                                <div className="file-holder">
                                  <LazyLoadImage
                                    src={
                                      message?.filetype?.includes("image")
                                        ? photoIcon
                                        : paperclip
                                    }
                                    alt={
                                      message?.filetype?.includes("image")
                                        ? "photo-icon"
                                        : "paper clip"
                                    }
                                  />
                                </div>
                                 
                                {message?.file && (
                                  <a
                                    href={message?.file}
                                    target="_blank"
                                    className="file-name-comment"
                                    style={{ color: "black" }}
                                  >
                                    File
                                  </a>
                                )}
                              </div>
                            );
                          } else {
                            return <div key={idx}>{breakLiner(item)}</div>;
                          }
                        })
                    ) : isIframe(message.content).includes("#?#?#") ? (
                      isIframe(message.content)
                        .split("#?#?#")
                        .map((item, idx) => {
                          if (item.includes("https")) {
                            return (
                              <div
                                className="file-number-wrapper"
                                style={{ alignItems: "center" }}
                              >
                                <div className="file-holder">
                                  <LazyLoadImage
                                    src={
                                      message?.filetype?.includes("image")
                                        ? photoIcon
                                        : paperclip
                                    }
                                    alt={
                                      message?.filetype?.includes("image")
                                        ? "photo-icon"
                                        : "paper clip"
                                    }
                                  />
                                </div>
                                 
                                {message?.file && (
                                  <a
                                    href={message?.file}
                                    target="_blank"
                                    className="file-name-comment"
                                    style={{ color: "black" }}
                                  >
                                    File
                                  </a>
                                )}
                              </div>
                            );
                          } else {
                            return <div key={idx}>{breakLiner(item)}</div>;
                          }
                        })
                    ) : (
                      breakLiner(message.content)
                    )}
                  </div>
                  <div className="message-created-at">
                    {`${t("Sent At")} ${ConvertingArabicNumberToEnglish(
                      moment(message?.createdAt).format("hh:mm ")
                    )} 
                ${t("On")}               
                  ${new Date(message?.createdAt)
                    .toLocaleDateString
                    // localStorage.getItem("i18nextLng")
                    ()}`}
                  </div>
                </div>
              ))}
            </div>
          ))}

        {data?.id === "filter-conversations-by-meta-modal" &&
          ["ADMIN", "SUPER-ADMIN"].includes(data?.role) && (
            <div className="assign-operator-input">
              <input
                id="search"
                type="text"
                value={name}
                placeholder={t("Enter a name if you want to save this filter")}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          )}
        {(data?.id === "assign-operator-modal" ||
          data?.id === "filter-conversation-by-operator-modal" ||
          data?.id === "filter-conversation-by-operator-modal" ||
          data?.id === "filter-conversations-by-department-modal") && (
          <div ref={wrapperRef}>
            <div className="assign-operator-input">
              <LazyLoadImage src={searchIcon} alt="search-icon" />
              <input
                id="search"
                type="text"
                placeholder={t("Search")}
                disabled={
                  data?.id === "filter-conversations-by-department-modal"
                    ? statusGetDepartments === "loading"
                    : statusOperatorsList === "loading"
                }
                onClick={() => {
                  setDisplay(true);
                }}
                value={
                  data?.id === "filter-conversations-by-department-modal"
                    ? searchDepartment
                    : search
                }
                onChange={(event) =>
                  data?.id === "filter-conversations-by-department-modal"
                    ? setSearchDepartment(event.target.value)
                    : setSearch(event.target.value)
                }
              />
            </div>

            {display && (
              <div className="operators-to-assign-container">
                {(data?.id === "filter-conversations-by-department-modal"
                  ? departments
                  : operators
                )?.length === 0 ? (
                  <span className="no-operators-style">
                    {t(
                      `There is no ${
                        data?.id === "filter-conversations-by-department-modal"
                          ? "departments"
                          : "operators"
                      }.`
                    )}
                    {["ADMIN", "SUPER-ADMIN"].includes(data?.role) &&
                      (() => {
                        return data?.id !==
                          "filter-conversations-by-department-modal" ? (
                          <span
                            onClick={() => {
                              history.push("/settings/users");
                              handleClose(id);
                            }}
                            className="no-operators-link"
                          >
                            {t("Try invite or create one")} 😃
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              history.push("/settings/departments");
                              handleClose(id);
                            }}
                            className="no-operators-link"
                          >
                            {t(`Try create one`)}
                            😃
                          </span>
                        );
                      })()}
                  </span>
                ) : data?.id === "filter-conversations-by-department-modal" ? (
                  departments
                    ?.filter(
                      (department) =>
                        `${department?.name}`
                          ?.toLowerCase()
                          .includes(searchDepartment.toLowerCase()) ||
                        `${department?.name}`.indexOf(
                          searchDepartment.toLowerCase()
                        ) > -1
                    )
                    .map((value) => {
                      return (
                        <div
                          key={value?._id}
                          className="operator-wrapper"
                          onClick={() => {
                            setDisplay(false);
                            setSearchDepartment(value?.name);
                            setDepartmentId(value?._id);
                          }}
                        >
                          <Avatar
                            src={departmentIcon}
                            className="operator-avatar-modal"
                          />
                          <span className="operator-name-modal">
                            {value?.name}
                          </span>
                        </div>
                      );
                    })
                ) : (
                  operators
                    ?.filter(
                      (operator) =>
                        (`${operator?.firstName} ${operator?.lastName}`
                          ?.toLowerCase()
                          .includes(search.toLowerCase()) ||
                          `${operator?.firstName} ${operator?.lastName}`.indexOf(
                            search.toLowerCase()
                          ) > -1) &&
                        operator?._id !== data?.operatorAssigned &&
                        operator?.isVerified !== false
                    )
                    .map((value) => {
                      return (
                        <div
                          key={value?._id}
                          className="operator-wrapper"
                          onClick={() => {
                            setDisplay(false);
                            setSearch(value?.firstName + " " + value?.lastName);
                            setOperatorId(value?._id);
                          }}
                        >
                          <Avatar
                            src={value?.avatar}
                            className="operator-avatar-modal"
                          />
                          <span className="operator-name-modal">
                            {value?.firstName + " " + value?.lastName}
                          </span>
                        </div>
                      );
                    })
                )}
              </div>
            )}
          </div>
        )}

        {data?.id === "filter-conversations-by-date-modal" && (
          <DateRange
            editableDateInputs={true}
            locale={
              localStorage.getItem("i18nextLng") === "ar"
                ? ar
                : localStorage.getItem("i18nextLng") === "fr"
                ? fr
                : enUS
            }
            onChange={(item) => setState([item.selection])}
            rangeColors={["#6932fa", "#3ecf8e", "#fed14c"]}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        )}

        {data?.id === "filter-conversations-by-time-modal" && (
          <div className="filter-conversations-by-time">
            <Select
              value={{ label: selectedValue.label }}
              styles={styles}
              style={{ height: "150px" }}
              placeholder={<div>{t("Type to search")}</div>}
              onChange={handleSelectChange}
              components={{ Option, DropdownIndicator }}
              options={constants.Time_Filter.map((item) => {
                return {
                  label: t(item.label),
                  value: item.value,
                };
              })}
            />
          </div>
        )}
        {data?.id === "filter-conversations-by-meta-modal" && (
          <div id="filter-by-meta">
            <div className="filter-conversations-by-meta">
              <Select
                styles={{
                  ...styles,
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #ebebeb",
                    width: "300px",
                    margin: "20px auto 0 auto",
                    borderRadius: "8px",
                    height: "50px",
                    backgroundColor: "white",
                    borderbottom: state.isFocused
                      ? "unset"
                      : "1px solid #ebebeb",
                    boxShadow: state.isFocused ? "0px " : "0px",
                    "&:hover": {
                      ...styles,
                    },
                  }),
                }}
                value={{
                  label: meta?.status ? (
                    <span style={{ fontSize: "16px" }}>{meta?.status}</span>
                  ) : (
                    <span style={{ fontSize: "16px" }}>
                      unread, unresolved or resolved...
                    </span>
                  ),
                }}
                components={{ Option, DropdownIndicator }}
                onChange={(event) =>
                  // staticProp is referred to name or phone or id or email
                  setMeta((prev) => {
                    return {
                      ...prev,
                      status: event.label,
                    };
                  })
                }
                options={[
                  { value: 1, label: "unread" },
                  { value: 2, label: "unresolved" },
                  { value: 3, label: "resolved" },
                ]}
              />
              <Button
                className={!meta?.status ? "reset-btn-disabled" : "reset-btn"}
                disabled={!meta?.status}
                onClick={() => {
                  const { status, ...newMeta } = meta;
                  setMeta(newMeta);
                }}
              >
                {t("Reset")}
              </Button>
            </div>

            <div className="meta-filter-input">
              <LazyLoadImage src={searchIcon} alt="search-icon" />
              <input
                id="search"
                type="text"
                placeholder={t("Search by name, email, phone, id...")}
                defaultValue={
                  !meta?.staticProp ? meta?.staticProp : query?.meta?.staticProp
                }
                onChange={(event) =>
                  // staticProp is referred to name or phone or id or email
                  setMeta((prev) => {
                    return { ...prev, staticProp: event.target.value };
                  })
                }
              />
            </div>
            <div className="meta-filter-text">
              {t("You Can Also Filter By Key And Value Pairs")}
            </div>
            <div>
              <IconButton
                onClick={() => {
                  setMeta((prev) => {
                    return {
                      ...prev,
                      optional: [
                        ...prev.optional,
                        { id: nanoid(), key: "", value: "" },
                      ],
                    };
                  });
                }}
              >
                <img src={plusIcon} alt="plus-icon" />
              </IconButton>
            </div>

            {meta.optional.map((item, index) => (
              <div className="filter-conversations-by-meta" key={index}>
                <Select
                  onMenuOpen={() => {
                    metaKeys?.length === 0 &&
                      dispatch(getMetaKeys(data?.websiteId));
                  }}
                  value={{
                    label: meta?.optional?.find((el) => el?.id === item?.id)
                      ?.key ? (
                      meta?.optional?.find((el) => el?.id === item?.id)?.key
                    ) : (
                      <span>{t("Type to search")}</span>
                    ),
                  }}
                  styles={styles}
                  onChange={(event) => {
                    setMeta((prev) => {
                      return {
                        ...prev,
                        optional: prev.optional.map((el, indexation) => {
                          return index === indexation
                            ? { ...item, key: event.label }
                            : el;
                        }),
                      };
                    });
                  }}
                  components={{ Option, DropdownIndicator }}
                  isLoading={statusGetMetaKeys === "loading"}
                  options={metaKeys?.map((key, index) => {
                    return { value: index, label: key };
                  })}
                />
                <div className="meta-filter-optional">
                  <ContentPasteOutlinedIcon className="key-value-icon" />
                  <input
                    id="search"
                    type="text"
                    placeholder={t("Value")}
                    defaultValue={
                      query?.meta?.optional?.find((el) => el?.id === item?.id)
                        ?.value
                    }
                    onChange={(event) =>
                      setMeta((prev) => {
                        return {
                          ...prev,
                          optional: prev.optional.map((el, indexation) => {
                            return index === indexation
                              ? { ...item, value: event.target.value }
                              : el;
                          }),
                        };
                      })
                    }
                  />
                </div>
                <IconButton
                  className="minus-icon"
                  onClick={() => {
                    setMeta((prev) => {
                      return {
                        ...prev,
                        optional: prev.optional.filter(
                          (el) => el?.id !== item?.id
                        ),
                      };
                    });
                  }}
                >
                  <RemoveCircleOutlineOutlinedIcon className="key-value-icon" />
                </IconButton>
              </div>
            ))}
          </div>
        )}
        {data?.id === "setup-reminder-modal" && (
          <div className="setup-reminder-input">
            <div className="setup-reminder-date">
              <LazyLoadImage src={dateIcon} alt="date-icon" />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  className="date-picker"
                  label={t("Select Your Date")}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <Divider
              orientation="vertical"
              className="setup-reminder-divider"
            />
            <div className="setup-reminder-time">
              <LazyLoadImage src={timeIcon} alt="date-icon" />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  label="Select Your Time"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
        )}
      </DialogContent>
      {data?.id !== "edit-message-history" && (
        <DialogActions className="assign-operator-btns">
          {data?.id === "filter-conversations-by-meta-modal" &&
          ["ADMIN", "SUPER-ADMIN"].includes(data?.role) ? (
            <Button
              className={
                status === "loading" || filterByMetaStatus === "loading"
                  ? "assign-operator-cancel-btn-disabled"
                  : "assign-operator-cancel-btn"
              }
              disabled={
                ((meta?.staticProp === "" || meta?.staticProp === undefined) &&
                  meta.optional.length === 0) ||
                name?.trim() === ""
              }
              onClick={() => {
                emptyKeyOrValue(meta.optional)
                  ? showSnackbar({
                      message: `please fill all your key and value pairs`,
                      variant: "warning",
                    })
                  : dispatch(
                      savePersonalFilter({
                        websiteID: data?.websiteId,
                        meta: {
                          ...meta,
                          name,
                          optional: meta?.optional?.map((el) => {
                            const { id, ...rest } = el;
                            return rest;
                          }),
                        },
                      })
                    ).then((res) => {
                      !res?.error
                        ? dispatch(
                            getAllConversationsByMeta({
                              websiteID: data?.websiteId,
                              meta,
                              page: 1,
                            })
                          ).then((res) => {
                            if (res.meta.requestStatus === "fulfilled") {
                              dispatch(resetPage());
                              dispatch(
                                getConversationQuery({
                                  websiteId: data?.websiteId,
                                  query: "meta",
                                  meta,
                                })
                              );
                              setMeta({ optional: [] });
                              setValue(null);
                              handleClose(id);
                              setName("");
                            }
                          })
                        : showSnackbar({
                            message: `${t(`${res.error?.message}`)}  `,
                            variant: "error",
                          });
                    });
              }}
            >
              {t("Save And Filter")}
            </Button>
          ) : (
            <Button
              id="assign-operator-cancel-btn"
              className="assign-operator-cancel-btn"
              onClick={() => {
                handleClose(id);
              }}
            >
              {t("Cancel")}
            </Button>
          )}

          <Button
            className={
              operators?.find(
                (operator) =>
                  search === operator?.firstName + " " + operator?.lastName
              ) ||
              (meta?.staticProp !== "" && meta?.staticProp !== undefined) ||
              meta.optional.length !== 0 ||
              searchDepartment !== ""
                ? "assign-operator-change-btn"
                : "assign-operator-change-btn-disabled"
            }
            disabled={
              (data?.id === "assign-operator-modal" &&
                !operators?.find(
                  (operator) =>
                    search === operator?.firstName + " " + operator?.lastName
                )) ||
              statusAssignClientToAnotherConversation === "loading" ||
              status === "loading" ||
              status === "pending" ||
              (data?.id === "filter-conversations-by-meta-modal" &&
                (meta?.staticProp === "" || meta?.staticProp === undefined) &&
                meta.optional.length === 0) ||
              (data?.id === "filter-conversations-by-department-modal" &&
                searchDepartment === "")
            }
            color="primary"
            onClick={() => {
              data?.id === "filter-conversations-by-department-modal" &&
                dispatch(
                  getAllConversationsByDepartment({
                    websiteId: data?.websiteId,
                    departmentId,
                    page: 1,
                  })
                ).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    setSearchDepartment("");
                    dispatch(resetPage());
                    dispatch(
                      getConversationQuery({
                        websiteId: data?.websiteId,
                        query: "department",
                        departmentId,
                        page: 1,
                      })
                    );
                    handleClose(id);
                  }
                });
              data?.id === "filter-conversations-by-time-modal" &&
                dispatch(
                  getAllConversationsByMinutes({
                    websiteId: data?.websiteId,
                    minutes: selectedValue.value,
                    page: 1,
                  })
                ).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    setSearch("");
                    dispatch(resetPage());
                    dispatch(
                      getConversationQuery({
                        websiteId: data?.websiteId,
                        query: "time",
                        minutes: selectedValue.value,
                        page: 1,
                      })
                    );
                    setSelectedValue({
                      label: t("Filter By Time"),
                      value: "",
                    });
                    handleClose(id);
                  }
                });
              data?.id === "filter-conversation-by-operator-modal" &&
                dispatch(
                  getAllConversationsByOperator({
                    websiteId: data?.websiteId,
                    operatorId,
                    page: 1,
                  })
                ).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    setSearch("");
                    dispatch(resetPage());
                    dispatch(
                      getConversationQuery({
                        websiteId: data?.websiteId,
                        query: "operator",
                        operatorId,
                        page: 1,
                      })
                    );
                    handleClose(id);
                  }
                });
              data?.id === "filter-conversations-by-date-modal" &&
                dispatch(
                  getAllConversationsByDate({
                    websiteId: data?.websiteId,
                    startDate: formatDate(state[0]?.startDate),
                    endDate: formatDate(state[0]?.endDate),
                    page: 1,
                  })
                ).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    setSearch("");
                    dispatch(resetPage());
                    dispatch(
                      getConversationQuery({
                        websiteId: data?.websiteId,
                        startDate: formatDate(state[0]?.startDate),
                        endDate: formatDate(state[0]?.endDate),
                        query: "date",
                      })
                    );
                    handleClose(id);
                  }
                });
              data?.id === "assign-operator-modal" &&
                dispatch(
                  assignConversationToAnotherOperator({
                    websiteId: data?.websiteId,
                    conversationId: data?.conversationId,
                    operatorId,
                    oldOperator: data?.oldOperator,
                  })
                ).then((res) => {
                  !res?.error
                    ? (() => {
                        showSnackbar({
                          message: `${t(
                            "This conversation has been assigned successfully to"
                          )} ${search} `,
                          variant: "success",
                        });
                        emitEvent("ASSIGNED_OPERATOR", {
                          webSiteId: data?.websiteId,
                          conversation: clientConversationStored?._id,
                          newAssignedOperator: operators?.find(
                            (operator) => operatorId === operator?._id
                          ),
                          oldOperator: clientConversationStored?.assigned?.user,
                          user: data?.user,
                          createdAt: res?.payload?.createdAt,
                        });
                        setSearch("");
                      })()
                    : showSnackbar({
                        message: "Invalid choice of operator",
                        variant: "error",
                      });
                  setSelectedValue({
                    label: t("Filter By Time"),
                    value: "",
                  });
                  handleClose(id);
                });
              data?.id === "filter-conversations-by-meta-modal" &&
                (emptyKeyOrValue(meta.optional)
                  ? showSnackbar({
                      message: `please fill all your key and value pairs`,
                      variant: "warning",
                    })
                  : dispatch(
                      getAllConversationsByMeta({
                        websiteID: data?.websiteId,
                        meta,
                        page: 1,
                      })
                    ).then((res) => {
                      if (res.meta.requestStatus === "fulfilled") {
                        dispatch(resetPage());
                        dispatch(
                          getConversationQuery({
                            websiteId: data?.websiteId,
                            query: "meta",
                            meta,
                          })
                        );
                        handleClose(id);
                      }
                    }));
            }}
          >
            {statusAssignClientToAnotherConversation === "loading" ||
            status === "loading" ||
            status === "pending" ? (
              <CircularProgress className="circular-progress-assign-operator" />
            ) : (
              data?.btn_text
            )}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
});

export default AssignOperatorModal;
